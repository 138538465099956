import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom"

import { Links } from "@links/Links"
import "./index.css"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />}>
          <Route path="*" element={<Links />} />
        </Route>
      </Routes>
      <Routes></Routes>
    </BrowserRouter>
  </React.StrictMode>
)

function App() {
  return (
    <main id="main">
      <Outlet />
    </main>
  )
}
